import useHidePriceOnDisabledMarkets from "hooks/useHidePriceOnDisabledMarkets"
import React from "react"
import { useTranslation } from "react-i18next"
import colors from "theme/colors"
import { makeStyles } from "tss-react/mui"

import Text, { TextProps } from "./Text"

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    whiteSpace: "nowrap",
  },
  sales: {
    marginRight: spacing(2),
    color: colors.red.light,
  },
  disabled: {
    textDecoration: "line-through",
  },
}))
export type PriceClassKey = "root" | "sales" | "disabled"

type PriceProps = {
  disabled?: boolean
  display?: "initial" | "block" | "inline"
  sale?: boolean
  showPriceWithTax?: boolean
} & TextProps

const Price: React.FC<PriceProps> = ({
  disabled,
  display = "inline",
  children,
  sale = false,
  showPriceWithTax,
  ...textProps
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const hidePrice = useHidePriceOnDisabledMarkets()

  if (hidePrice) {
    return null
  }

  return (
    <Text
      bold
      display={display}
      className={cx("ig-price", classes.root, {
        "ig-price--sale": sale,
        [classes.sales]: sale,
        "ig-price--disabled": disabled,
        [classes.disabled]: disabled,
      })}
      {...textProps}
    >
      {children}
      {children && showPriceWithTax === true && (
        <> {t("product.price_incl_tax")}</>
      )}
      {children && showPriceWithTax === false && (
        <> {t("product.price_excl_tax")}</>
      )}
    </Text>
  )
}

export default Price
