import { ContentResponse } from "@ignite/api/contents"
import { UserResponse } from "@ignite/api/users"
import { AsyncState } from "@ignite/react/context"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box from "@mui/material/Box"
import CartButton from "components/Cart/CartButton"
import SearchButton from "components/IgniteSearch/SearchButton"
import LanguagePicker from "components/LanguagePicker"
import MarketPicker from "components/MarketPicker"
import MegaMenu from "components/MegaMenu"
import MobileMenuButton from "components/MobileMenu/MobileMenuButton"
import React from "react"
import { makeStyles } from "tss-react/mui"

import BusinessPagesToolbar from "./BusinessPagesToolbar"
import HeaderLogo from "./HeaderLogo"

type ContentProps = {
  showCart: boolean
  isCrawler?: boolean
  isMobileBusinessPages?: boolean
  page: AsyncState<ContentResponse | null>
  userMe: UserResponse | null
  useSimpleHeader?: boolean
}

export default function Content({
  showCart,
  isCrawler,
  isMobileBusinessPages,
  page,
  userMe,
  useSimpleHeader,
}: ContentProps) {
  const { classes } = useStyles()
  const isMobile = useBreakpoint("md")

  //Prevents stupid flickering in the mobile menu when the other
  //Menu toolbar is supposed to be rendered
  if (userMe !== undefined && page.loading === true) return null

  return (
    <>
      {!isMobile && !useSimpleHeader && (
        <>
          <MegaMenu className={classes.megamenu} />
          <Box className={classes.quickmenu}>
            <Box mx={2}>
              <MarketPicker />
            </Box>
            <Box mx={2}>
              <LanguagePicker />
            </Box>
            <SearchButton />
            {showCart && <CartButton />}
          </Box>
        </>
      )}

      {isMobile && page && isMobileBusinessPages && (
        <BusinessPagesToolbar isCrawler={isCrawler} page={page} />
      )}
      {isMobile && page && !isMobileBusinessPages && (
        <>
          <Box display="flex" width={70}>
            <HeaderLogo position="absolute" top={0} />
          </Box>
          {!useSimpleHeader && (
            <>
              <Box display="flex" flex={1} justifyContent="flex-end">
                <SearchButton />
                {showCart && <CartButton />}
                <MobileMenuButton edge={false} />
              </Box>
              {/* Required for SEO */}
              {isCrawler && (
                <MegaMenu
                  className={classes.megamenu}
                  display={
                    "none" /* Hidden on mobile- & crawler-versions for SEO support */
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles()(({ breakpoints, spacing, typography }) => ({
  megamenu: {
    whiteSpace: "nowrap",
    [breakpoints.only("md")]: {
      "& > ul": {
        justifyContent: "flex-start",
        "& > li": {
          "& > div": {
            padding: spacing(0, 2),
            "& > a": {
              fontSize: "16px",
            },
          },
        },
      },
    },
  },

  quickmenu: {
    marginLeft: "auto",
    flex: 1,
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "flex-end",
    display: "flex",
    "& > div:first-of-type": {
      position: "relative",
      "&:after": {
        width: "1px",
        content: "''",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        position: "absolute",
        height: "14px",
        bottom: "calc(50% - 7px)",
        right: "-6px",
      },
    },
    "& button": {
      ...typography["primary-200"],
    },
  },
}))
