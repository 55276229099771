import { useMenuContext } from "@ignite/react/context/menuContext"
import { usePageContext } from "@ignite/react/context/pageContext"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import { useUserContext } from "@ignite/react/context/userContext"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import jwt from "@ignite/services/jwt"
import { isSearchBot } from "@ignite/utils/device"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import Cart from "components/Cart"
import CheckIcon from "components/Icons/CheckIcon"
import LogoutIcon from "components/Icons/LogoutIcon"
import ImpersonateUsersTopBar from "components/Impersonate/ImpersonateUsersTopBar"
import MenuPicker from "components/MenuPicker"
import MobileMenu from "components/MobileMenu"
import Search from "components/Search"
import Slogan from "components/Slogan"
import Snackbar from "components/Snackbar"
import Text from "components/Text"
import UniqueSellingPoints from "components/UniqueSellingPoints"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "tss-react/mui"

import AuthSection from "./AuthSection"
import Content from "./Content"
import HeaderCheckout from "./HeaderCheckout"
import HeaderContainer from "./HeaderContainer"
import HeaderLogo from "./HeaderLogo"
import Marquee from "./Marquee"
import TopSection from "./TopSection"

const useStyles = makeStyles()(
  ({ spacing, breakpoints, typography, palette }) => ({
    mobilemenu: {
      "& .toolbar": {
        minHeight: 60,
      },
    },
    userLabel: {
      fontSize: typography["primary-100"].fontsize,
      lineHeight: typography["primary-200"].lineHeight,
      fontWeight: typography["primary-100"].fontWeight,
      marginLeft: spacing(6),
    },
    logoutIcon: {
      width: 12,
      height: 12,
      marginRight: spacing(2),
    },
    marquee: {
      "& *": {
        verticalAlign: "middle",
      },
      "& svg": {
        marginRight: spacing(1),
        width: "12px",
        [breakpoints.only("xs")]: {
          width: "10px",
        },
      },
    },
    usp: {
      overflowX: "auto",
      overflowY: "hidden",
      scrollbarWidth: "none",
      WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    uspWithIcons: {
      "& img": {
        marginRight: spacing(2),
      },
    },
    uspindrawer: {
      "&> li": {
        display: "flex",
        padding: `${spacing(3)} 0`,
        alignItems: "center",
        gap: spacing(2),
        "& span": {
          color: palette.grey[700],
        },
        "& img": {
          filter:
            "brightness(0) saturate(100%) invert(27%) sepia(12%) saturate(4977%) hue-rotate(120deg) brightness(100%) contrast(101%)",
        },
      },
    },
    snackbar: {
      "& span": {
        fontFamily: typography?.fontFamily,
        padding: "0 2px",
      },
    },
    categoryBreadcrumbs: {
      position: "relative",
      width: "100%",
      paddingBottom: spacing(4),
    },
    mobileBusinessPagesClass: {
      "& .MuiToolbar-root": {
        paddingLeft: 12,
      },
    },
    logoutBox: {
      cursor: "pointer",
      marginLeft: spacing(4),
    },
  })
)

const Header: React.FC = () => {
  const {
    state: { siteSettings },
  } = useSiteSettingsContext()
  const {
    state: { page },
  } = usePageContext()
  const {
    state: { menus },
  } = useMenuContext()

  const {
    state: {
      user: { data: userMe },
    },
  } = useUserContext()

  const headerMenu = useMemo(
    () => menus.data?.find((menu) => menu.id === "header") ?? null,
    [menus]
  )
  const isCheckoutPage = page.data?.type.toLowerCase() === "checkoutpage"

  // Nelson only wants breadcrumbs on these pages for now, until we have a new design for it.
  const breadcrumbsPages = [
    "igniteproduct",
    "ignitebundle",
    // "landingpage",
    "ignitecategory",
    // "contentpage",
    // "journalpage",
  ]

  const mobileBusinessPages = [
    "businesscartpage",
    "businesscheckoutpage",
    "orderhistorypage",
    "businesscompanyinformationpage",
    "businesscustomerservicepage",
  ]

  const isMobileBusinessPages = Boolean(
    mobileBusinessPages.find((p) => p === page.data?.type.toLowerCase())
  )
  const isBreadcrumbsPage = Boolean(
    breadcrumbsPages.find((p) => p === page.data?.type.toLowerCase())
  )

  const isCrawler = isSearchBot() // Crawlers like Google, Prerender.io, etc.

  const isConfirmationPage =
    page.data?.type.toLowerCase() === "orderconfirmationpage"

  const showCheckoutHeader = isCheckoutPage || isConfirmationPage

  const isStaticPage = page.data?.type.toLowerCase() === "staticpage"

  const isSmall = useBreakpoint("md")

  const { classes, cx } = useStyles()
  const links = useSystemLinks()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const resellerPortalDisabled =
    siteSettings?.data?.properties["resellerPortalDisabled"]
  const uspWithIcons =
    siteSettings?.data?.properties["uniqueSellingPointsWithIcons"]
  const slogan = siteSettings?.data?.properties["slogan"]
  const showCart = !siteSettings?.data?.properties.commerceDisabled

  const handleLogout = async () => {
    jwt.clear()
    navigate("/")
    window.location.reload()
  }

  const topSection = (
    <TopSection alignItems="flex-start" px={9}>
      <Box width={130} minWidth={130} position="relative">
        <HeaderLogo
          link={
            isStaticPage
              ? null
              : `${
                  window["HOME_PAGE_ROUTE"]
                    ? window["HOME_PAGE_ROUTE"]?.toLowerCase()
                    : "/"
                }`
          }
          position="absolute"
          zIndex={99}
        />
      </Box>
      {(slogan || uspWithIcons || !resellerPortalDisabled || headerMenu) &&
        !isStaticPage && (
          <Box
            className={classes.usp}
            display="flex"
            alignItems="center"
            height="100%"
            justifyContent={slogan ? "space-between" : "flex-end"}
            whiteSpace="nowrap"
            flexGrow={1}
          >
            {slogan && (
              <Box ml={2}>
                <Slogan content={slogan} />
              </Box>
            )}
            <Box display="flex" alignItems="center">
              {uspWithIcons && !userMe && (
                <UniqueSellingPoints
                  contentWithIcons={uspWithIcons}
                  className={classes.uspWithIcons}
                />
              )}
              {!resellerPortalDisabled && !isStaticPage && (
                <Box ml={10}>
                  <AuthSection />
                </Box>
              )}
              {headerMenu && !userMe && !isStaticPage && (
                <MenuPicker
                  content={{
                    ...headerMenu,
                    children: [...headerMenu.children].reverse(),
                  }}
                />
              )}
              {userMe && (
                <>
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.logoutBox}
                      onClick={handleLogout}
                    >
                      <LogoutIcon className={classes.logoutIcon} />
                      <Text component="p">
                        {t("mypages.header_cta_logout")}
                      </Text>
                    </Box>
                    <Text component="p" className={classes.userLabel}>
                      {t("retailer_portal.auth_logged_in_as")}{" "}
                      <strong>{userMe.fullName}</strong>
                    </Text>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
    </TopSection>
  )

  return (
    <>
      {showCheckoutHeader && (
        <HeaderCheckout
          containerProps={{
            hideOnScroll: false,
          }}
          leftComponentProps={{
            onClick: (e: React.MouseEvent) => {
              e.preventDefault()
              navigate(
                `${
                  window["HOME_PAGE_ROUTE"]
                    ? window["HOME_PAGE_ROUTE"]?.toLowerCase()
                    : "/"
                }`
              )
            },
          }}
          rightComponentProps={{
            href: links["checkout-help"],
          }}
        />
      )}
      {!showCheckoutHeader && (
        <>
          <HeaderContainer
            className={cx({
              [classes.mobileBusinessPagesClass]: isMobileBusinessPages,
            })}
            topSection={
              <>
                <ImpersonateUsersTopBar />
                {isSmall ? null : topSection}
              </>
            }
            marquee={
              !isStaticPage && (
                <Marquee
                  boxShadow="none"
                  className={classes.marquee}
                  iconStart={<CheckIcon size="sm" />}
                />
              )
            }
          >
            <Search />
            <Cart cartName="basket" />
            <MobileMenu
              className={classes.mobilemenu}
              anchor="right"
              LogoComponentProps={{ position: "absolute", top: 0 }}
              toolbarContent={
                slogan && (
                  <Slogan
                    content={slogan}
                    display="flex"
                    alignItems="center"
                    marginLeft={20}
                    flexGrow={1}
                    minHeight={60}
                  />
                )
              }
            >
              {uspWithIcons && !userMe && (
                <Box py={8}>
                  <UniqueSellingPoints
                    className={classes.uspindrawer}
                    contentWithIcons={uspWithIcons}
                    stacked
                  />
                  {isBreadcrumbsPage && (
                    <Breadcrumbs parents={(page.data as any)?.parents} />
                  )}
                </Box>
              )}
              {headerMenu && !userMe && (
                <MenuPicker content={headerMenu} mobileView />
              )}
            </MobileMenu>

            <Content
              useSimpleHeader={isStaticPage}
              showCart={showCart}
              isCrawler={isCrawler}
              isMobileBusinessPages={isMobileBusinessPages}
              userMe={userMe}
              page={page}
            />
          </HeaderContainer>

          {isBreadcrumbsPage && !isSmall ? (
            <Breadcrumbs
              parents={(page.data as any)?.parents}
              className={classes.categoryBreadcrumbs}
            />
          ) : // Mobile view
          isBreadcrumbsPage && isSmall ? (
            <Breadcrumbs parents={(page.data as any)?.parents} />
          ) : null}
        </>
      )}
      <Snackbar className={classes.snackbar} />
    </>
  )
}

export default Header
