import { usePageContext } from "@ignite/react/context/pageContext"
import Box from "@mui/material/Box"
import Spinner from "components/Spinner"
import React from "react"
import { Helmet } from "react-helmet-async"
import { makeStyles } from "tss-react/mui"

import { PageRenderer } from "./ContentRenderer"

export const isItemComponent = (entry: any) =>
  entry &&
  typeof entry === "object" &&
  entry.type &&
  (entry.type.endsWith("Block") || entry.type.endsWith("Page"))

const useStyles = makeStyles()(() => ({
  root: {
    minHeight: "70vh",
  },
}))

const ContentProvider: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.root, "fade")}>
      {(page.loading && <Spinner />) ||
        (page.error && (
          <PageRenderer
            fallback={<Spinner />}
            content={{
              id: "error",
              type: "ErrorPage",
              properties: Object.assign(
                {
                  title: "Error",
                },
                page.error
              ),
            }}
          />
        )) ||
        (page && page.data && (
          <>
            <Helmet>
              <title>{page.data.meta.title}</title>
              {page.data.meta.metaDescription && (
                <meta
                  name="description"
                  content={page.data.meta.metaDescription}
                />
              )}
              {page.data.type !== "SearchResultsPage" ? (
                page.data.meta?.canonicalUrl &&
                page.data.meta?.canonicalUrl !== "" ? (
                  <link rel="canonical" href={page.data.meta?.canonicalUrl} />
                ) : (
                  <link rel="canonical" href={page.data.url} />
                )
              ) : null}
            </Helmet>
            <PageRenderer content={page.data} />
          </>
        )) ||
        (null as any)}
    </Box>
  )
}

export default ContentProvider
